

import { defineComponent, reactive, toRefs, onMounted } from 'vue'
import { getArticles } from '@/apis/articles'
import { ArticleModel } from '@/model/articleModel'
import { formatJson } from '@/utils'
import { exportTxt2Zip } from '@/utils/zip'

export default defineComponent({
  setup() {
    const dataMap = reactive({
      list: Array<ArticleModel>(),
      listLoading: true,
      downloadLoading: false,
      filename: ''
    })

    const fetchData = async() => {
      dataMap.listLoading = true
      const data = await getArticles({ /* Your params here */ })
      dataMap.list = data?.data.items ?? []
      // Just to simulate the time of the request
      setTimeout(() => {
        dataMap.listLoading = false
      }, 0.5 * 1000)
    }

    const handleDownload = () => {
      dataMap.downloadLoading = true
      const tHeader = ['Id', 'Title', 'Author', 'Readings', 'Date']
      const filterVal = ['id', 'title', 'author', 'pageviews', 'timestamp']
      const list = dataMap.list
      const data = formatJson(filterVal, list)
      if (dataMap.filename !== '') {
        exportTxt2Zip(tHeader, data, dataMap.filename, dataMap.filename)
      } else {
        exportTxt2Zip(tHeader, data)
      }
      dataMap.downloadLoading = false
    }
    onMounted(() => {
      fetchData()
    })
    return { ...toRefs(dataMap), handleDownload, fetchData }
  }
})
